import React from 'react';
import ReactDOM from 'react-dom';
import Web3EthContract from 'web3-eth-contract';
import { Web3ReactProvider } from '@web3-react/core';
import { ToastContainer } from 'react-toastify';

import App from './App';

import 'react-toastify/dist/ReactToastify.css';

const getLibrary = (provider, connector) => {
  return Web3EthContract.setProvider(provider);
};

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <App />
      <ToastContainer />
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
