import { useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import Web3EthContract from 'web3-eth-contract';

import { tokenAddress } from './contract/addresses';
import token_ABI from './contract/token_ABI.json';

function useContract(abi, address) {
  const { active } = useWeb3React();

  return useMemo(() => {
    if (active) {
      return new Web3EthContract(abi, address);
    } else {
      return null;
    }
  }, [active, address, abi]);
}

export function useTokenContract() {
  return useContract(token_ABI, tokenAddress);
}
