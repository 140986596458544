import {
  Fragment,
  useState,
  useRef,
  useCallback,
  useEffect,
  useLayoutEffect,
} from 'react';
import { useWeb3React } from '@web3-react/core';
import { fromWei } from 'web3-utils';
import ProgressBar from 'progressbar.js';
import { toast } from 'react-toastify';

import { useTokenContract } from './hooks';
import { injected } from './connectors';

const offset = 50;
const url =
  process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8080';
let line;

function App() {
  const { active, account, activate } = useWeb3React();
  const tokenContract = useTokenContract();
  const [bros, setBros] = useState([]);
  const [soldTokens, setSoldTokens] = useState('0');
  const [tokenPrice, setTokenPrice] = useState('0');
  const progressLineRef = useRef();
  const pageProgressWrapRef = useRef();
  const pageProgressPathRef = useRef();

  const init = useCallback(async () => {
    let index = 0;
    const tokenIdPromises = [];
    const tokenUriPromises = [];
    const bros = [];
    if (account && tokenContract) {
      try {
        const soldTokens = await tokenContract.methods.SoldTokens().call();
        const tokenPrice = await tokenContract.methods.tokenPrice().call();

        setSoldTokens(soldTokens);
        setTokenPrice(fromWei(tokenPrice));

        const idLength = await tokenContract.methods.balanceOf(account).call();

        while (index < idLength) {
          tokenIdPromises.push(
            tokenContract.methods.TokenIDs(account, index).call()
          );
          index++;
        }

        const tokenIds = await Promise.all(tokenIdPromises);

        console.log(tokenIds);
        for (const uri of tokenIds) {
          tokenUriPromises.push(
            await tokenContract.methods.tokenURI(uri).call()
          );
        }
        for (const uri of tokenUriPromises) {
          const response = await fetch(uri);
          const data = await response.json();

          bros.push(data);
        }

        setBros(bros);
      } catch (error) {
        console.error('init ERROR', error);
      }
    }
  }, [account, tokenContract]);

  const handleMintBro = useCallback(async () => {
    let id;
    if (account && tokenContract) {
      try {
        id = toast.loading('Transaction Inprocess!');

        const response = await fetch(`https://brosbros.herokuapp.com/add`, { method: 'POST' });
        const data = await response.json();

        const tokenPrice = await tokenContract.methods.tokenPrice().call();
        await tokenContract.methods.mintTokens(data.Hash).send({
          from: account,
          value: tokenPrice,
        });

        await fetch(`https://brosbros.herokuapp.com/update`, { method: 'POST' });

        init();

        toast.update(id, {
          render: 'Transaction Completed!',
          type: 'success',
          isLoading: false,
          autoClose: 2000,
        });
      } catch (error) {
        toast.update(id, {
          render: 'Transaction Failed!',
          type: 'error',
          isLoading: false,
          autoClose: 2000,
        });

        // eslint-disable-next-line no-console
        console.error('handleMintBro ERROR', error);
      }
    } else {
      toast.error('Connect To MetaMask!');
    }
  }, [account, tokenContract, init]);

  const scrollListener = useCallback(() => {
    const pathLength = pageProgressPathRef.current.getTotalLength();
    const scroll = window.pageYOffset;
    const height = document.documentElement.scrollHeight - window.innerHeight;
    const progress = pathLength - (scroll * pathLength) / height;
    console.log('progress', progress);

    pageProgressPathRef.current.style.strokeDashoffset = progress;

    if (window.pageYOffset > offset) {
      pageProgressWrapRef.current.classList.add('active-progress');
    } else {
      pageProgressWrapRef.current.classList.remove('active-progress');
    }
  }, []);

  useEffect(() => {
    init();

    activate(injected);
  }, [activate, init]);

  useEffect(() => {
    if (active) {
      var remaining = 2000 - +soldTokens;
      console.log(+remaining / 2000, '+soldTokens');
      line.animate(+remaining / 2000);
    }
  }, [active, soldTokens]);

  useLayoutEffect(() => {
    const pathLength = pageProgressPathRef.current.getTotalLength();
    pageProgressPathRef.current.style.transition =
      pageProgressPathRef.current.style.WebkitTransition = 'none';
    pageProgressPathRef.current.style.strokeDasharray =
      pathLength + ' ' + pathLength;
    pageProgressPathRef.current.style.strokeDashoffset = pathLength;
    pageProgressPathRef.current.getBoundingClientRect();
    pageProgressPathRef.current.style.transition =
      pageProgressPathRef.current.style.WebkitTransition =
        'stroke-dashoffset 10ms linear';

    line = new ProgressBar.Line(progressLineRef?.current, {
      strokeWidth: 3,
      trailWidth: 3,
      duration: 3000,
      easing: 'easeInOut',
      text: {
        autoStyleContainer: false,
        style: {
          color: 'inherit',
          position: 'absolute',
          right: '0',
          top: '-30px',
          padding: 0,
          margin: 0,
          transform: null,
        },
      },
      step: function (state, line, attachment) {
        line.setText(Math.round(line.value() * 100) + ' %');
      },
    });
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('scroll', scrollListener);
  });

  return (
    <>
      <div className="content-wrapper">
        <header className="wrapper bg-soft-primary">
          <br />
          <button
            className="btn btn-primary rounded-pill me-5 float-end"
            onClick={active ? undefined : () => activate(injected)}
          >
            {active ? 'Connected' : 'Connect'}
          </button>
          <br />
          <br />
          <br />
          <center>
            <a href="/">
              <img
                style={{ width: 510, maxWidth: '90%' }}
                src="img/logonohead.png"
                alt=""
              />
            </a>
          </center>
        </header>
        {/* /header */}

        <section className="wrapper bg-gradient-primary">
          <div className="container pt-10 pt-md-14 pb-8 text-center">
            <div className="row gx-lg-8 gx-xl-12 gy-10 ">
              <div className="col-lg-6">
                <figure>
                  <img className="w-auto" src="img/brosgif.gif" alt="" />
                </figure>
                <br />
                <br />
                <i>
                  <b>
                    Over 100 Unique Attributes. <a href="/"> Rarity Chart </a>
                  </b>
                </i>
              </div>
              {/* /column */}
              <div className="col-md-10 col-lg-5 text-center text-lg-start">
                <h1 className="display-1 mb-5 mx-md-n5 mx-lg-0">
                  Now available for minting
                </h1>
                <p className="lead fs-lg mb-7">
                  AVAX Bros is a collection of 2000 randomly generated
                  characters. Ready to mint your own unique AVAX Bro?
                </p>
                <span>
                  <button
                    className="btn btn-primary rounded-pill me-2"
                    onClick={handleMintBro}
                  >
                    MINT A BRO
                  </button>
                </span>{' '}
                <b>{active ? `${tokenPrice} AVAX` : 'Connect to see price'}</b>
                <br />
                <br />
                <i>
                  <b>
                    2% chance of minting a <a href="/">Super Bro</a>
                  </b>
                </i>
                <br />
                <i>Price increases 0.1 AVAX every 100 mints</i>
                <br />
                <br />
                <p>
                  <b>{active ? 2000 - +soldTokens : '????'}/2000 Remaining</b>
                </p>
                <div
                  ref={progressLineRef}
                  className="progressbar line soft-leaf"
                  data-value={85}
                />
              </div>
              {/* /column */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </section>
        {/* /section */}

        <section className="wrapper bg-light">
          <div className="container pt-14 pt-md-16">
            {active ? (
              <div className="row text-center">
                <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                  <h3 className="display-4 mb-10 px-xl-10">Your Bros</h3>
                  <div className="row gx-lg-8 gx-xl-12 gy-6 mb-6">
                    {/*/column */}
                    {bros.map((bro) => (
                      <div key={bro.name} className="col-md-6 col-lg-4">
                        <img src={bro.image} width={200} alt={bro.name} />
                        <p>{bro.name}</p>
                        {bro.attributes.map((attr) => (
                          <Fragment key={attr.trait_type}>
                            <p>
                              {attr.trait_type}: {attr.value}
                            </p>
                            {/* <p>Body: Yellow</p>
                            <p>Mouth: Yellow</p>
                            <p>Eyes: Yellow</p>
                            <p>Neck: Yellow</p> */}
                          </Fragment>
                        ))}
                      </div>
                    ))}

                    {/*/column */}
                  </div>
                </div>
                {/* /.row */}
              </div>
            ) : (
              <img
                src="/img/connect.gif"
                alt="Connect Your Wallet"
                className="w-100"
              />
            )}
          </div>
          {/* /.container */}
        </section>
        {/* /section */}
      </div>
      {/* /.content-wrapper */}
      <footer>
        <div className="container pb-7">
          <div className="row gx-lg-0 gy-6">
            <div className="col-lg-4">{/* /.widget */}</div>
            {/* /column */}
            <div className="col-lg-3 offset-lg-2">{/* /.widget */}</div>
            {/* /column */}
          </div>
          {/*/.row */}
          <hr className="mt-13 mt-md-14 mb-7" />
          <div className="d-md-flex align-items-center justify-content-between">
            <p className="mb-2 mb-lg-0">
              © 2021 AVAX Bros. All rights reserved.
            </p>
            <nav className="nav social social-muted mb-0 text-md-end">
              <a href="/">
                <i className="uil uil-twitter" />
              </a>
              <a href="/">
                <i className="uil uil-telegram" />
              </a>
            </nav>
            {/* /.social */}
          </div>
        </div>
        {/* /.container */}
      </footer>

      <div
        ref={pageProgressWrapRef}
        className="progress-wrap"
        onClick={() => window.scroll({ top: 0, behavior: 'smooth' })}
      >
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path
            ref={pageProgressPathRef}
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
          />
        </svg>
      </div>
    </>
  );
}

export default App;
